import {
  baseVariantId,
  cpc_calculatorId,
  cpc_waitforcartconfirmation,
  baseCalculatorNewApiUrl,
  calculatorStyling,
  cpc_data,
  cpc_configData,
  cpc_shopUrl,
  cpc_product_default_price,
  cpc_isPreview,
  cpc_productId,
  cpc_metaFields,
  productForm,
  cdnURL,
  cpc_languageCode
} from "./global_variables";
import { buildCalculator } from "./build_elements";
import { SubmitFormData, GetVariantProperties } from "./checkout_helper";
import {
  showDialog,
  globalHelper_ReportError,
  PreventCheckoutOnEnter,
} from "./common_utils";
import {
  ValidateInputs,
  loadScripts,
  UpdateAllHiddenElements,
  UpdateAllSelectedCheckBox,
} from "./everything_else";
import { firstLoad } from "./price_calculation";

require("jquery-ui/themes/base/dialog.css");
require("jquery-ui/ui/widgets/dialog");

async function LoadCalculator(calculatorId, isPreview) {
  var url =
    baseCalculatorNewApiUrl +
    "calculator/app?calculatorId=" +
    calculatorId +
    "&productId=" +
    cpc_productId +
    "&isPreview=" +
    isPreview +
    "&shop=" +
    cpc_shopUrl+
    "&languageCode=" +
    cpc_languageCode;
  return await fetch(url);
}

export async function ProcessProductPage() {
  var submitButton;
  var calculatorId;
  jQuery('form[action*="/cart/add"]').each(function (i, e) {
    var isCpcFormOverride = jQuery(e).data("cpc-product-form");

    if (
      e.querySelector('[type="submit"]') &&
      (submitButton === undefined ||
        (isCpcFormOverride !== undefined && isCpcFormOverride == true))
    ) {
      submitButton = null !== e ? e.querySelector('[type="submit"]') : null;
      productForm = e;
    }
    if (
      e.querySelector('button[class*="cart"]') &&
      (submitButton === undefined ||
        (isCpcFormOverride !== undefined && isCpcFormOverride == true))
    ) {
      submitButton =
        null !== e ? e.querySelector('button[class*="cart"]') : null;
      productForm = e;
    }
  });

  // this.productForm = productForm;
  // this.submitButton = submitButton;

  if (cpc_productId > 0) {
    var calcProduct = await fetchCalculatorByProduct();
    if (
      calcProduct != null &&
      calcProduct.calcUniqueIden != null &&
      calcProduct.calcUniqueIden != undefined &&
      calcProduct.calcUniqueIden != ""
    ) {
      console.log("CalculatorId found from API");
      calculatorId = calcProduct.calcUniqueIden;
    }
  }
  //Fall back to old way if we cant find the calculatorId from the serer
  if (calculatorId == null || calculatorId == 0 || calculatorId == undefined) {
    var calculatorElement = document.getElementById("calculator-block");
    var customCalculatorDiv = document.getElementById("custom-calculator");
    calculatorId = jQuery(customCalculatorDiv).attr("data-calculatorid");

    if (calculatorId != null && calculatorId != undefined)
      console.log("CalculatorId found from Fallback Method", calculatorId);
    else {
      console.log("No calculator found");
      return;
    }
  }

  if (calculatorId != null && calculatorId != undefined) {
    cpc_calculatorId = calculatorId;
    console.log("calculator found", calculatorId);
    console.log("product Id", cpc_productId);

    loadScripts();
    await fetchProductMetaData();

    var priceElements = [
      "product-price",
      "product-single__price",
      "selector-wrapper",
      "current_price",
      "product__price",
      "shopify-payment-button",
      "single-option-selector",
    ];
    priceElements.forEach((elem) => {
      var elementToHide = jQuery("." + elem);
      elementToHide.addClass("hide-element");
    });

    //Change the old element so that you can create new element with same id
    var variantSelector = document.querySelector('select[name="id"]');
    if (variantSelector !== null) {
      baseVariantId = variantSelector.value;
      variantSelector.setAttribute("name", "oldId");
    }

    var variantSelector = document.querySelector('input[name="id"]');
    if (variantSelector !== null) {
      baseVariantId = variantSelector.value;
      variantSelector.setAttribute("name", "oldId");
    }

    productForm.addEventListener("submit", function (t) {
      t.preventDefault();
      t.stopImmediatePropagation();
      t.stopPropagation();
    });

    //Load css

    var fileref = document.createElement("link");
    fileref.setAttribute("rel", "stylesheet");
    fileref.setAttribute("type", "text/css");
    fileref.setAttribute(
      "href",
      baseCalculatorNewApiUrl + "store/style/app?shop=" + cpc_shopUrl
    );
    document.getElementsByTagName("head")[0].appendChild(fileref);

    if (!document.getElementById("cdn-plugin-css")) {
      var pluginCss = document.createElement("link");
      pluginCss.setAttribute("rel", "stylesheet");
      pluginCss.setAttribute("type", "text/css");
      pluginCss.setAttribute("href", `${cdnURL}/plugins.css`);
      pluginCss.id = "cdn-plugin-css";
      document.getElementsByTagName("head")[0].appendChild(pluginCss);
    }

    //Remove all listeners
    var el = submitButton;
    let elClone = el.cloneNode(true);
    if (!!el && !!el.parentNode) el.parentNode.replaceChild(elClone, el);
    else el.replaceWith(elClone);

    submitButton = elClone;

    submitButton.addEventListener("click", await HandleAddToCartEvent);

    //To hide the loader once page has loaded
    var customCalcBlock = jQuery("#custom-calculator");
    customCalcBlock.hide();

    var calculatorData = LoadCalculator(calculatorId, cpc_isPreview);
    calculatorData
      .then((response) => response.json())
      .then((json) => {
        cpc_data = json.calculatorData;
        cpc_metaFields = json.metaFields;
        let config = {
          smartVariantAutoDeletion: false,
          alertMessageNumberInput:
            "Please enter a value between range {input_range_min} and {input_range_max}",
          thousandSeperator: 0,
          decimalSeperator: 0,
        };
        if (!!json.configuration) config = json.configuration;
        localStorage.setItem("cpc_configData", JSON.stringify(config));
        cpc_configData = config;
        calculatorStyling = json.styling;
        cpc_waitforcartconfirmation = json.waitForCartConfirmation;

        console.log("meta-fields", cpc_metaFields);

        var builtData = buildCalculator(cpc_data);

        var elem = document.querySelector(".add-to-cart"); //jQuery(".add-to-cart");
        if (elem == null) {
          elem = document.querySelector(
            "form[action='/cart/add'] button[type=submit]"
          );
        }
        if (elem == null) {
          elem = submitButton;
        }
        elem = submitButton;
        console.log("render-block", elem);

        var borderClass =
          calculatorStyling.border === "rounded" ? "class='rounded-edges'" : "";
        jQuery(elem)
          .parent()
          .prepend(
            "<div id='calculator' data-calcid='" +
              calculatorId +
              "' " +
              borderClass +
              " style='background-color:" +
              calculatorStyling.upperBackground +
              "'>" +
              builtData +
              "</div>"
          );

        //Custom Store JS
        var shopCustomJsPath =
          baseCalculatorNewApiUrl + "store/script/app?shop=" + cpc_shopUrl;
        if (shopCustomJsPath != null && shopCustomJsPath.length > 0) {
          var customStoreJSRef = document.createElement("script");
          customStoreJSRef.setAttribute("type", "text/javascript");
          customStoreJSRef.setAttribute("src", shopCustomJsPath);
          customStoreJSRef.defer = true;
          customStoreJSRef.setAttribute("crossorigin", "anonymous");
          document
            .getElementsByTagName("head")[0]
            .appendChild(customStoreJSRef);
        }

        firstLoad();
        PreventCheckoutOnEnter();
      });
  }
}

async function fetchCalculatorByProduct() {
  try {
    let cpcCalcualtor;
    await fetch(
      baseCalculatorNewApiUrl + "calculator/app/productid/" + cpc_productId
    )
      .then((response) => response.json())
      .then(async function (cpc_calcProduct) {
        if (cpc_calcProduct !== undefined) {
          cpcCalcualtor = cpc_calcProduct;
        }
      });
    return cpcCalcualtor;
  } catch (e) {}
}

async function HandleAddToCartEvent(t) {
  t.preventDefault();
  t.stopImmediatePropagation();
  t.stopPropagation();
  jQuery(".loading-anim").show();

  var props = GetVariantProperties();

  var validationError = ValidateInputs();
  if (validationError === true) {
    jQuery(".loading-anim").hide();
    return;
  }

  //Create Variant
  jQuery
    .ajax({
      method: "POST",
      url: baseCalculatorNewApiUrl + "variant",
      data: JSON.stringify({
        calculatorId: cpc_calculatorId,
        productId: window.meta.product.id,
        properties: { ...props },
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .done(function (variantId) {
      var quantity = 1;
      var quantSelector = jQuery("#Quantity");
      if (quantSelector) {
        var n = t.value;
        if (n) {
          quantity = Number(n);
        }
      }

      if (typeof variantId === "object" && variantId !== null) {
        showDialog();
        jQuery(".loading-anim").hide();
        return;
      }

      var variantInput = document.getElementById("variantIdCustom");
      variantInput.value = variantId;
      UpdateAllSelectedCheckBox();
      UpdateAllHiddenElements();
      SubmitFormData();

      return;
    })
    .fail(function (jqXHR, textStatus, errorThrown) {
      console.error("Error:", textStatus, errorThrown);
      let errorMessage = "An error occurred during the request: " + textStatus;
      if (jqXHR.status === 400) {
        errorMessage = jqXHR.responseText;
      } else if (jqXHR.status === 500) {
        errorMessage = jqXHR.responseText;
      } else if (textStatus === "timeout") {
        errorMessage = "Request timed out.";
      } else if (textStatus === "abort") {
        errorMessage = "Request was aborted.";
      }
      $("#errDialog").attr("title", "Error");
      $("#dialogMsg").text(errorMessage.replace(/"/g, ""));
      $("#errDialog").dialog({ modal: true });
    });
}

async function fetchProductMetaData() {
  try {
    if (cpc_isPreview === false) {
      let cpc_product_page_url = window.location.href.split("?")[0];
      let previewKey = window.location.href.split("?")[1];
      if (previewKey === undefined) previewKey = "";
      let hasPreviewKey = previewKey.startsWith("preview_key");
      var productFetchUrl = cpc_product_page_url + ".js?d=" + Math.random();
      if (hasPreviewKey === true)
        productFetchUrl =
          cpc_product_page_url + ".js?" + previewKey + "&d=" + Math.random();

      await fetch(productFetchUrl)
        .then((response) => response.json())
        .then(async function (cpc_product_meta_data) {
          if (cpc_product_meta_data !== undefined) {
            var default_variant = cpc_product_meta_data.variants[0];
            if (default_variant !== undefined) {
              cpc_product_default_price = default_variant.price / 100;
            }
          }
        });
      console.log("shopify product price", cpc_product_default_price);
    } else {
      console.log("Calculator in preview mode");
      cpc_product_default_price = 10;
    }
  } catch (e) {
    var errMsg = "Shopify Price Error:. ProductId =" + cpc_productId;
    globalHelper_ReportError(e, errMsg);
    cpc_product_default_price = 0;

    setTimeout(function () {
      window.location.reload();
    }, 3000);
  }
}
